<template>
  <div class="example-page" v-if="showNav" v-bind:style="backgroundStyle">
    <nav class="test-nav">
      <router-link class="test-nav-item" to="/category">category</router-link> |
      <router-link class="test-nav-item" to="/category/Een laatste kus"
        >category kus</router-link
      >
      |
      <router-link class="test-nav-item" to="/product/NL-50021332"
        >product</router-link
      >
      |
      <router-link class="test-nav-item" to="/cart">open cart</router-link> |
      <a @click="postMessage('open-cat', 'Natuurlijk afscheid')"
        >open category
      </a>
      |
      <a @click="postMessage('open-prod', 'NL-50021711')"
        >open product item rood hard
      </a>
    </nav>
  </div>
  <router-view />
</template>
<script>
import { provideCart } from "@/services/Cart";

export default {
  setup() {
    const cart = provideCart();
    cart.retrieveLocalStorage();
  },
  computed: {
    showNav() {
      return process.env.NODE_ENV === "development";
    },
    backgroundStyle() {
      if (process.env.NODE_ENV === "development") {
        return {
          backgroundImage: `url(${require("@/assets/images/tmp-bg.jpg")})`,
        };
      }
      return {};
    },
  },
  methods: {
    postMessage(message, value) {
      window.postMessage({ message, value });
    },
  },
};
</script>
<style>
@import "assets/css/webflow.css";
@import "assets/css/fleurop.css";
.test-nav {
  color: #c8c8c8;
}
.test-nav-item {
  color: #c8c8c8;
}
</style>
