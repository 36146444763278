<template>
  <!-- OVERLAY CONTAINER -->
  <div class="cart-overlay">
    <!-- HOOFD POPUP DISPLAY FLEX -->
    <div class="product-overlay-bg" style="display: flex">
      <div @click="$router.push('/')" class="product-overlay-close"></div>
      <div class="product-overlay-container">
        <closeButton />
        <!-- SUBPOPUP - WINKELMAND -->
        <div id="cart" class="view cart">
          <div class="title-back">
            <div v-if="backEnabled" class="cart-go-back" @click="back()">
              <svg
                alt="back"
                width="9"
                height="15"
                viewBox="0 0 9 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.921875 7.40625C0.707031 7.62109 0.707031 7.92187 0.921875 8.13672L7.23828 14.4961C7.41016 14.7109 7.75391 14.7109 7.96875 14.4961L8.78516 13.6367C9 13.4648 9 13.1211 8.78516 12.9062L3.71484 7.79297L8.78516 2.63672C9 2.42188 9 2.12109 8.78516 1.90625L7.96875 1.04687C7.75391 0.832031 7.41016 0.832031 7.23828 1.04687L0.921875 7.40625Z"
                  fill="black"
                />
              </svg>
            </div>
            <div class="container-heading">Aanvraagformulier</div>
          </div>
          <p class="view-intro">
            Controleer hieronder of de aanvraag klopt. Wanneer de aanvraag juist
            is kun je verder gaan met het invullen van de benodigde gegevens om
            de aanvraag te voltooien.
          </p>
          <div class="cart-items">
            <div class="w-form">
              <form
                v-if="cartObject.state.cart?.length"
                id="order-form"
                name="order-form"
                method="get"
                v-on:submit.prevent="(e) => submitCart(e)"
              >
                <!-- Item row -->
                <div>
                  <div
                    v-for="cartItem in cartObject.state.cart"
                    :key="cartItem.id"
                    class="cart-row-item"
                  >
                    <img
                      @click="imageClick(cartItem)"
                      sizes="100vw"
                      :src="cartItem.image"
                      loading="lazy"
                      alt=""
                      class="thumb-product"
                    />
                    <div class="cart-row-desc">
                      <div class="cart-item-title">
                        {{ cartItem.name }}
                      </div>
                      <div class="cart-item-desc">
                        {{ cartItem.sku }}, {{ cartItem.size }}
                        {{ cartItem.sizeDescription }}, {{ cartItem.shape }}
                      </div>
                      <div v-if="cartItem.extra.extra" class="cart-item-desc">
                        {{ cartItem.extra.value }}:
                        <p v-if="cartItem.customColor">
                          Kleur: {{ cartItem.customColor }}
                        </p>
                        <p
                          v-for="text in cartItem.customText"
                          :key="text"
                          v-html="text"
                        ></p>
                      </div>
                    </div>
                    <div class="cart-row-options">
                      <input
                        type="text"
                        class="cart-input small w-input"
                        maxlength="256"
                        :name="'amount_' + cartItem.id"
                        :value="cartItem.amount"
                        @change="(e) => cartItemChange(cartItem, e)"
                        id="amount"
                        required="true"
                      />
                      <div class="cart-item-price">
                        {{ cartItem.totalPriceFormat }}
                      </div>
                      <div
                        alt="delete"
                        class="cart-item-delete"
                        @click="cartObject.removeCartItem(cartItem.id)"
                      >
                        <svg
                          width="13"
                          height="15"
                          viewBox="0 0 13 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.8125 1.625H8.53125L8.25781 1.13281C8.14844 0.914062 7.92969 0.75 7.68359 0.75H4.53906C4.29297 0.75 4.07422 0.914062 3.96484 1.13281L3.71875 1.625H0.4375C0.191406 1.625 0 1.84375 0 2.0625V2.9375C0 3.18359 0.191406 3.375 0.4375 3.375H11.8125C12.0312 3.375 12.25 3.18359 12.25 2.9375V2.0625C12.25 1.84375 12.0312 1.625 11.8125 1.625ZM1.44922 13.5195C1.47656 14.2305 2.05078 14.75 2.76172 14.75H9.46094C10.1719 14.75 10.7461 14.2305 10.7734 13.5195L11.375 4.25H0.875L1.44922 13.5195Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <!-- Totals -->
                  <div class="cart-total-row">
                    <div class="cart-total-price">
                      <div>Totaalkosten</div>
                      <div>{{ cartObject.state.totalPriceFormat }}</div>
                    </div>
                    <p class="cart-shipping-costs">
                      Kosten incl. BTW en exclusief verzending
                    </p>
                  </div>
                </div>

                <!-- Order form -->
                <div class="cart-order-title">Geef je aanvraag door</div>
                <div>
                  <div class="cart-form-group">
                    <label for="company" class="cart-label">Naam bedrijf</label>
                    <input
                      type="text"
                      v-model="store.state.naw.company"
                      class="cart-input no-pad w-input"
                      maxlength="256"
                      name="company"
                      placeholder=""
                    />
                  </div>
                  <div class="cart-form-group">
                    <label for="name" class="cart-label"
                      >Naam<span class="cart-required">*</span></label
                    >
                    <input
                      type="text"
                      v-model="store.state.naw.name"
                      class="cart-input no-pad w-input"
                      maxlength="256"
                      name="name"
                      required=""
                    />
                  </div>
                  <div class="cart-form-group">
                    <label for="address" class="cart-label"
                      >Adres<span class="cart-required">*</span></label
                    >
                    <input
                      type="text"
                      class="cart-input no-pad w-input"
                      maxlength="256"
                      name="address"
                      v-model="store.state.naw.address"
                      required=""
                    />
                  </div>
                  <div class="cart-form-group">
                    <label for="zipcode" class="cart-label"
                      >Postcode<span class="cart-required">*</span></label
                    >
                    <input
                      type="text"
                      class="cart-input no-pad medium w-input"
                      maxlength="256"
                      name="zipcode"
                      v-model="store.state.naw.zipcode"
                      required=""
                    />
                  </div>
                  <div class="cart-form-group">
                    <label for="city" class="cart-label"
                      >Plaats<span class="cart-required">*</span></label
                    >
                    <input
                      type="text"
                      v-model="store.state.naw.city"
                      class="cart-input no-pad w-input"
                      maxlength="256"
                      name="city"
                      required
                    />
                  </div>
                  <div class="cart-form-group">
                    <label for="phone" class="cart-label">Telefoonnummer</label>
                    <input
                      type="tel"
                      v-model="store.state.naw.phone"
                      class="cart-input no-pad w-input"
                      maxlength="256"
                      name="phone"
                    />
                  </div>
                  <div class="cart-form-group">
                    <label for="email" class="cart-label"
                      >E-mail<span class="cart-required">*</span></label
                    >
                    <input
                      type="text"
                      v-model="store.state.naw.email"
                      class="cart-input no-pad w-input"
                      maxlength="256"
                      name="email"
                      required
                      pattern="[\w\-\.]+@([\w\-]+\.)+[\w\-]{2,4}"
                      title="Vul een correct email adres in"
                    />
                  </div>
                  <div class="cart-form-group">
                    <div class="cart-emailcc">
                      <label for="email2" class="cart-label"
                        >E-mail voor bevestiging</label
                      >
                      <span
                        >Stuur de bevestiging van deze aanvraag extra naar het
                        volgende adres</span
                      >
                    </div>
                    <input
                      type="text"
                      v-model="store.state.naw.email_cc"
                      class="cart-input no-pad w-input"
                      maxlength="256"
                      name="email2"
                      pattern="[\w\-\.]+@([\w\-]+\.)+[\w\-]{2,4}"
                      title="Vul een correct email adres in"
                    />
                  </div>
                  <div class="cart-order-title">Gegevens uitvaart</div>
                  <div>
                    <div class="cart-form-group">
                      <label for="funeral_home" class="cart-label"
                        >Rouwcentrum</label
                      >
                      <input
                        type="text"
                        v-model="store.state.naw.funeral_home"
                        class="cart-input no-pad w-input"
                        maxlength="256"
                        name="funeral_home"
                        placeholder=""
                      />
                    </div>
                    <div class="cart-form-group">
                      <label for="funeral_address" class="cart-label"
                        >Adres</label
                      >
                      <input
                        type="text"
                        v-model="store.state.naw.funeral_address"
                        class="cart-input no-pad w-input"
                        maxlength="256"
                        name="funeral_address"
                      />
                    </div>
                    <div class="cart-form-group">
                      <label for="funeral_zipcode" class="cart-label"
                        >Postcode</label
                      >
                      <input
                        type="text"
                        class="cart-input no-pad medium w-input"
                        maxlength="256"
                        name="funeral_zipcode"
                        v-model="store.state.naw.funeral_zipcode"
                      />
                    </div>
                    <div class="cart-form-group">
                      <label for="funeral_city" class="cart-label"
                        >Plaats</label
                      >
                      <input
                        type="text"
                        class="cart-input no-pad w-input"
                        maxlength="256"
                        name="funeral_city"
                        v-model="store.state.naw.funeral_city"
                      />
                    </div>
                    <div class="cart-form-group">
                      <label for="funeral_phone" class="cart-label"
                        >Telefoonnummer uitvaartbegeleider</label
                      >
                      <input
                        type="tel"
                        v-model="store.state.naw.funeral_phone"
                        class="cart-input no-pad w-input"
                        maxlength="256"
                        name="funeral_phone"
                      />
                    </div>
                    <div class="cart-form-group">
                      <label for="funeral_deceased" class="cart-label"
                        >Naam Overledene</label
                      >
                      <input
                        type="text"
                        v-model="store.state.naw.funeral_deceased"
                        class="cart-input no-pad w-input"
                        maxlength="256"
                        name="funeral_deceased"
                      />
                    </div>
                    <div class="cart-form-group">
                      <label for="funeral_date" class="cart-label"
                        >Uitvaartdatum</label
                      >
                      <input
                        type="text"
                        v-model="store.state.naw.funeral_date"
                        class="cart-input no-pad w-input"
                        maxlength="256"
                        name="funeral_date"
                      />
                    </div>
                    <div class="cart-form-group">
                      <label for="funeral_time" class="cart-label"
                        >Uitvaarttijd</label
                      >
                      <input
                        type="text"
                        v-model="store.state.naw.funeral_time"
                        class="cart-input no-pad w-input"
                        maxlength="256"
                        name="funeral_time"
                      />
                    </div>
                    <div class="cart-form-group">
                      <label for="funeral_remarks" class="cart-label"
                        >Opmerkingen / bijzonderheden</label
                      >
                      <input
                        type="text"
                        v-model="store.state.naw.funeral_remarks"
                        class="cart-input no-pad w-input"
                        maxlength="256"
                        name="funeral_remarks"
                      />
                    </div>
                    <div class="cart-form-group">
                      <label for="funeral_invoicenumber" class="cart-label"
                        >Factuurnummer</label
                      >
                      <input
                        type="text"
                        v-model="store.state.naw.funeral_invoicenumber"
                        class="cart-input no-pad w-input"
                        maxlength="256"
                        name="funeral_invoicenumber"
                      />
                    </div>
                    <div class="cart-form-group">&nbsp;</div>

                    <input
                      type="submit"
                      :disabled="isDisabled"
                      value="Aanvraag versturen"
                      data-wait="Please wait..."
                      class="cart-submit w-button"
                    />
                  </div>
                </div>
              </form>
              <div v-else>je winkelwagen is nog leeg</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from "@/components/closeButton";
import { useCart } from "@/services/Cart";
import { useStore } from "vuex";
import { computed } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "CartView",
  components: { CloseButton },
  setup() {
    const store = useStore();
    const cartObject = useCart();
    const router = useRouter();
    function cartItemChange(cartItem, amount) {
      cartObject.editCartItem(cartItem, amount.target.value);
    }
    async function submitCart(event) {
      event.stopPropagation();
      await cartObject
        .submitCart()
        .then(function () {
          cartObject.clearCart();
          router.push({ name: "thanks" });
        })
        // eslint-disable-next-line
        .catch((e) => console.log("Error", e));
      return false;
    }
    const isDisabled = computed(() => cartObject.state.cart.length == 0);

    const backEnabled = computed(
      () =>
        String(router.options.history.state.back) !== "/" &&
        String(router.options.history.state.back) !== ""
    );

    function back() {
      if (
        String(router.options.history.state.back) === "/" ||
        String(router.options.history.state.back) === ""
      ) {
        return router.push("/");
      }
      router.back();
    }
    function imageClick(item) {
      router.push("product/" + item.sku);
    }

    return {
      cartObject,
      cartItemChange,
      store,
      submitCart,
      backEnabled,
      back,
      isDisabled,
      imageClick,
    };
  },
};
</script>
<style>
.cart-submit:disabled {
  background-color: #aaadb0;
}
.cart-emailcc {
  width: 270px;
}
</style>
