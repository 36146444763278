<template>
  <!-- OVERLAY CONTAINER -->
  <div class="cart-overlay">
    <!-- HOOFD POPUP DISPLAY FLEX -->
    <div class="product-overlay-bg" style="display: flex">
      <div @click="$router.push('/')" class="product-overlay-close"></div>
      <div class="product-overlay-container">
        <close-button />
        <!-- SUBPOPUP - CATEGORY OVERZICHT -->
        <div id="category" class="view category">
          <div class="title-back">
            <div class="container-heading">{{ state.name }}</div>
          </div>
          <div class="product-overview">
            <div
              v-for="item in state.categories"
              :key="item.name"
              class="product-container"
              @click="selectCategory(item)"
            >
              <img
                :src="item.image"
                loading="lazy"
                sizes="100vw"
                :alt="item.name"
                class="image"
              />
              <div class="product-name">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from "@/components/closeButton";
import { reactive } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";

export default {
  name: "CategoryOverview",
  components: { CloseButton },
  setup() {
    const state = reactive({
      categories: [],
      name: "Categorie overzicht",
    });
    const router = useRouter();

    store.getters.getData.then(function (response) {
      state.categories = response.categories;
    });

    function selectCategory(item) {
      router.push({ name: "category", params: { name: item.name } });
    }

    return { state, selectCategory };
  },
};
</script>
