<template>
  <div class="cart-overlay">
    <div class="product-overlay-bg" style="display: flex">
      <div @click="$router.push('/')" class="product-overlay-close"></div>
      <div class="product-overlay-container">
        <close-button />
        <div id="cart" class="view thankyou">
          <div class="title-back">
            <div class="container-heading">Bedankt voor je aanvraag</div>
          </div>
          <p class="cart-thankyou">
            Bedankt voor je aanvraag. We nemen zo snel mogelijk contact met je
            op om de details van je aanvraag verder te bespreken.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from "@/components/closeButton";
export default {
  name: "ThanksView",
  components: { CloseButton },
};
</script>
