<template>
  <router-link :to="{ name: 'home' }">
    <div alt="close-icon" class="close-container">
      <svg
        width="26"
        height="25"
        viewBox="0 0 26 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="23.9601"
          y1="1.74694"
          x2="2.74687"
          y2="22.9601"
          stroke="black"
          stroke-width="3"
        />
        <line
          x1="23.2531"
          y1="22.9602"
          x2="2.03986"
          y2="1.74696"
          stroke="black"
          stroke-width="3"
        />
      </svg>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "closeButton",
};
</script>

<style scoped></style>
