import { createStore } from "vuex";

export default createStore({
  state: {
    axiosClient: null,
    categories: [],
    products: [],
    allProducts: [],
    naw: {
      company: "",
      name: "",
      address: "",
      zipcode: "",
      city: "",
      phone: "",
      email: "",
      email_cc: "",
    },
  },
  getters: {
    getData: async function (state) {
      function handleSku(sku) {
        const skuSize = sku.substr(-1, 1);
        const skuProduct = sku.substr(-4, 2);
        const skuShape = sku.substr(-5, 1);
        return { skuSize, skuProduct, skuShape };
      }

      if (state.products.length === 0) {
        return await fetch(process.env.VUE_APP_FEED)
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            if (response.success) {
              state.allProducts = JSON.parse(response.body);

              state.allProducts = state.allProducts.map(function (item) {
                const { skuProduct, skuShape, skuSize } = handleSku(item.sku);
                return {
                  name: item.Naam,
                  description: item.Omschrijving,
                  price: parseFloat(item.Prijs),
                  image: item.image_link,
                  categorie: item["maglr-cat"],
                  skuParent: item["sku-parent"],
                  sku: item.sku,
                  size: item.formaat,
                  shape: item.Vorm,
                  sizeDescription: item["formaat-omschrijving"],
                  skuProduct,
                  skuShape,
                  skuSize,
                };
              });
              state.allProducts = state.allProducts.sort(function (a, b) {
                if (a.skuSize < b.skuSize) {
                  return -1;
                }
                if (a.skuSize > b.skuSize) {
                  return 1;
                }
                if (a.skuShape < b.skuShape) {
                  return -1;
                }
                if (a.skuShape > b.skuShape) {
                  return 1;
                }
                return 0;
              });

              state.products = state.allProducts.reduce(function (
                value,
                currentValue
              ) {
                var item = value.find(
                  (element) => element.name === currentValue.name
                  // (element) => element.skuProduct === currentValue.skuProduct &&
                  // element.categorie === currentValue.categorie
                  // (element) => element.sku === currentValue.skuParent
                );
                if (item === undefined) {
                  value.push({
                    ...currentValue,
                    sku: currentValue.skuParent,
                    variant: [currentValue],
                  });
                } else {
                  if (item.price < currentValue.price) {
                    item.image = currentValue.image;
                  }
                  item.price = Math.min(item.price, currentValue.price);
                  item.variant.push(currentValue);
                }
                return value;
              },
              []);

              // eslint-disable-next-line no-unused-vars
              state.categories = state.products.reduce(function (
                value,
                currentValue
              ) {
                if (currentValue.categorie?.length > 0) {
                  var item = value.find(
                    (element) => element.name === currentValue.categorie
                  );
                  if (item === undefined) {
                    value.push({
                      name: currentValue.categorie,
                      image: currentValue.image,
                      price: currentValue.price,
                    });
                  } else {
                    if (item.price < currentValue.price) {
                      item.image = currentValue.image;
                    }
                    item.price = Math.min(item.price, currentValue.price);
                  }
                }

                return value;
              },
              []);

              return state;
            } else {
              return response.body;
            }
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.error("Error", e);
          });
      }
      return new Promise(function (myResolve) {
        myResolve(state);
      });
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
