import { computed, inject, provide, reactive } from "vue";
import store from "@/store";
import axios from "axios";

export const CartKey = Symbol("CartKey");

export function createCart() {
  const state = reactive({
    counter: 0,
    cart: [],
    totalPrice: 0,
    totalPriceFormat: 0,
    countItems: 0,
    totalAmount: 0,
  });
  const priceFormatter = computed(
    () =>
      new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      })
  );

  function addCartItem(item) {
    item.customText = item.customText?.map(function (item) {
      return item
        .replace(/<(?!br\s*\/?)[^>]+>/gi, "")
        .replace(/(?:\r\n|\r|\n)/g, "<br>");
    });
    state.cart.push({
      id: ++state.counter,
      ...item,
      totalPrice: calcItemPrice(item),
      totalPriceFormat: priceFormatter.value.format(calcItemPrice(item)),
    });
    calcTotals();
    storeLocalStorage();
  }

  function calcTotals() {
    const result = state.cart.reduce(
      function (total, item) {
        total.totalPrice += item.totalPrice;
        total.totalAmount += +item.amount;
        total.countItems++;
        return total;
      },
      { totalPrice: 0, totalAmount: 0, countItems: 0 }
    );
    state.totalPrice = result.totalPrice;
    state.totalPriceFormat = priceFormatter.value.format(result.totalPrice);
    state.totalAmount = result.totalAmount;
    state.countItems = result.countItems;
  }

  function calcItemPrice(item) {
    return (item.price + item.extra?.price) * item.amount;
  }

  function editCartItem(cartItem, amount) {
    const foundItem = state.cart.find((item) => item.id === cartItem.id);
    if (foundItem !== undefined) {
      if (amount >= 0) {
        foundItem.amount = amount;
      }
      (foundItem.totalPrice = calcItemPrice(foundItem)),
        (foundItem.totalPriceFormat = priceFormatter.value.format(
          foundItem.totalPrice
        ));
      calcTotals();
      storeLocalStorage();
    }
  }

  function removeCartItem(id) {
    const index = state.cart.findIndex((item) => item.id === id);
    if (index !== -1) {
      state.cart.splice(index, 1);
    }
    calcTotals();
    storeLocalStorage();
  }

  function storeLocalStorage() {
    localStorage.setItem("Cart", JSON.stringify(state.cart));
  }

  function retrieveLocalStorage() {
    const cart = JSON.parse(localStorage.getItem("Cart"));
    if (cart?.length > 0) {
      store.getters.getData.then(function (response) {
        for (const cartkey in cart) {
          if (cart[cartkey].amount > 0) {
            const product = response.allProducts.find(
              (item) => item.sku === cart[cartkey].sku
            );
            if (product !== undefined) {
              addCartItem({
                ...product,
                extra: cart[cartkey].extra,
                customText: cart[cartkey].customText,
                customColor: cart[cartkey].customColor,
                amount: cart[cartkey].amount,
              });
            }
          }
        }
      });
    }
  }
  async function submitCart() {
    return new Promise(function (resolve, reject) {
      if (process.env.VUE_APP_CART_WEBHOOK === undefined) {
        // eslint-disable-next-line
        console.error("Error VUE_APP_CART_WEBHOOK not set");
        reject("Error VUE_APP_CART_WEBHOOK not set");
      }
      const shopdata = {};
      for (const i in window.replaceables?.global) {
        shopdata[i.replace(/\[|\]/gi, "")] = window.replaceables?.global[i];
      }
      if (
        store.state.naw.email.trim().toLowerCase() ===
        store.state.naw.email_cc.trim().toLowerCase()
      ) {
        store.state.naw.email_cc = "";
      }
      axios
        .post(
          process.env.VUE_APP_CART_WEBHOOK,
          {
            date: new Date(Date.now()).toLocaleString(),
            title: "fleurop cart submit",
            cart: state.cart,
            naw: store.state.naw,
            totalprice: state.totalPriceFormat,
            shop: shopdata,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            resolve("OK");
          } else {
            // eslint-disable-next-line
            console.error("error in submit");
            reject("Error", response);
          }
        })
        .catch((e) => reject(e));
    });
  }
  function clearCart() {
    state.counter = 0;
    state.cart = [];
    state.totalPrice = 0;
    state.totalPriceFormat = 0;
    state.countItems = 0;
    state.totalAmount = 0;
    localStorage.clear();
  }

  return {
    state,
    retrieveLocalStorage,
    addCartItem,
    removeCartItem,
    editCartItem,
    submitCart,
    clearCart,
  };
}

export function provideCart() {
  const inst = createCart();
  provide(CartKey, inst);
  return inst;
}

export function useCart() {
  const inst = inject(CartKey);

  if (!inst) throw new Error("Cartjs use error");

  return inst;
}
