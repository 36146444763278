import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/category",
    name: "categoryOverview",
    component: () => import("../views/CategoryOverview.vue"),
  },
  {
    path: "/category/:name",
    name: "category",
    component: () => import("../views/ProductOverview.vue"),
  },
  {
    path: "/product/:sku",
    name: "product",
    component: () => import("../views/ProductItem.vue"),
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("../views/CartView.vue"),
  },
  {
    path: "/thanks",
    name: "thanks",
    component: () => import("../views/ThanksView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
