import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

createApp(App).use(store).use(router).mount("#app");

window.addEventListener("message", function (event) {
  if (event.data.message === "open-cat-overview") {
    router.push("/category");
  }
  if (event.data.message === "open-cat") {
    router.push("/category/" + event.data.value);
  }
  if (event.data.message == "open-prod") {
    router.push("/product/" + event.data.value);
  }
  if (event.data.message == "open-cart") {
    router.push("/cart");
  }
});
