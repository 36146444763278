<template>
  <!-- OVERLAY CONTAINER -->
  <div class="cart-overlay">
    <!-- HOOFD POPUP DISPLAY FLEX -->
    <div class="product-overlay-bg" style="display: flex">
      <div @click="$router.push('/')" class="product-overlay-close"></div>
      <div class="product-overlay-container">
        <close-button />
        <!-- SUBPOPUP - CATEGORY OVERZICHT -->
        <div id="category" class="view category">
          <div class="title-back">
            <div
              v-if="state.categoryEnabled"
              class="cart-go-back"
              @click="back()"
            >
              <svg
                width="9"
                height="15"
                viewBox="0 0 9 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.921875 7.40625C0.707031 7.62109 0.707031 7.92187 0.921875 8.13672L7.23828 14.4961C7.41016 14.7109 7.75391 14.7109 7.96875 14.4961L8.78516 13.6367C9 13.4648 9 13.1211 8.78516 12.9062L3.71484 7.79297L8.78516 2.63672C9 2.42188 9 2.12109 8.78516 1.90625L7.96875 1.04687C7.75391 0.832031 7.41016 0.832031 7.23828 1.04687L0.921875 7.40625Z"
                  fill="black"
                />
              </svg>
            </div>
            <div class="container-heading">{{ state.name }}</div>
          </div>
          <div class="product-overview">
            <div
              v-for="item in state.products"
              :key="item.name"
              class="product-container"
              @click="selectProduct(item)"
            >
              <img
                :src="item.image"
                loading="lazy"
                sizes="100vw"
                :alt="item.name"
                class="image"
              />
              <div class="product-name">{{ item.name }} {{ item.sku }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from "@/components/closeButton";
import { computed, reactive } from "vue";
import store from "@/store";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "ProductOverview",
  components: { CloseButton },
  setup() {
    const state = reactive({
      products: [],
      name: "product",
    });
    const router = useRouter();
    const route = useRoute();
    // const route = useRoute();

    state.name = computed(function () {
      return (
        store.state.categories.find((item) => item.name === route.params.name)
          .name ?? ""
      );
    });

    state.products = computed(function () {
      return store.state.products.filter(
        (item) => item.categorie === route.params.name
      );
    });
    state.categoryEnabled = computed(function () {
      return process.env.VUE_APP_CATEGORY_ENABLED === "true";
    });
    store.getters.getData;

    function selectProduct(item) {
      router.push({ name: "product", params: { sku: item.sku } });
    }
    function back() {
      router.push({ name: "categoryOverview" });
    }

    return { state, selectProduct, back };
  },
};
</script>
